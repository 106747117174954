<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Innovative Web Development Solutions</h1>
                            <p>Contact us today to see how we can help with your software needs!</p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>We Provide All Kind of Tech Solutions</h2>
            <p>From Informational/Business Website Development to Admin & Customer systems.  Our services range from the simplest of your businesses' software needs to complex custom solutions.  Contact us today to discuss your goals.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Website Design</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Website Development</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Admin Software</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IT Management</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Data Security</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Customer Management Software</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Innovative Software Development Solutions</h2>
                        <p>Providing high quality software and reliable service is our mission.  Over the years we have serviced high-profile companies and helped them acheive their unique software goals.</p>
                    </div>

                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Service</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
\        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div class="faq-item" *ngFor="let item of accordionItems; let i = index;">
                            <div class="faq-header" (click)="toggleAccordionItem(item)">
                                {{item.title}}
                                <i class='bx' [ngClass]="{'bx-plus': !item.open, 'bx-x': item.open}"></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{item.content}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>






