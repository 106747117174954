<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                    </div>

                   

                    <ul class="social">
                       
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/">Website Design</a></li>
                        <li><a routerLink="/">Website Development</a></li>
                        <li><a routerLink="/">QA & Testing</a></li>
                        <li><a routerLink="/">IT Management</a></li>
                        <li><a routerLink="/">Cyber Security</a></li>
                    </ul>
                </div>
            </div>



            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+1123456789"><i class="bx bx-mobile-alt"></i> +1 702 326 9861</a></li>
                        <li><a href="mailto:hello@aiva.com"><i class="bx bxs-envelope"></i> chris@cbawebdesign.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        
                    </ul>
                </div>

                <div class="col-lg-6">
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>